var tabs4i = function(){
	let allTabs, tabLinks, tabPanels;
	
	const init = async (element) =>{
		allTabs = element.querySelectorAll(".tabLink");
		tabPanels = element.querySelectorAll(".tabContent");
		await findVisibleTabs();
		addEvents();
	},
	findVisibleTabs = () => {
		tabLinks = [];
		for(let i = 0; i < allTabs.length; i++){
			if(isVisible(allTabs[i])) tabLinks.push(allTabs[i]);
		}
	},
	isVisible = (el) =>{
		return el.offsetWidth > 0 || el.offsetHeight > 0;
	},
	addEvents = () => {
		for(let i = 0; i < tabLinks.length; i++){
			tabLinks[i].removeEventListener("click", openTab);
	    		tabLinks[i].addEventListener("click", openTab);
	  	}
	  	
		tabKeys();
	},
	openTab = (e) =>{
		e.preventDefault();
		resetTabs();
            	initTab(e.currentTarget);
	},
	resetTabs = () =>{
		for(let i = 0; i < tabLinks.length; i++){
			deactivateTab(tabLinks[i]);
	  	}
	  	for(let i = 0; i < tabPanels.length; i++){
	  		hideTabContent(tabPanels[i]);
	  	}
	},
	initTab = (tabLink) =>{ // select/show specific tab
		if(tabLink){
			let targetContent = "";
			if(tabLink.getAttribute("data-content") !== null) targetContent = tabLink.getAttribute("data-content");
			else targetContent = tabLink.getAttribute("aria-controls");

			activateTab(tabLink);
			showTabContent(document.getElementById(targetContent));
		}
	},
	activateTab = (tabLink) => {
		tabLink.classList.add("active");
		tabLink.setAttribute("aria-selected", "true");
		tabLink.setAttribute("tabindex", "0");
	},
	deactivateTab = (tabLink) => {
		tabLink.setAttribute('tabindex', '-1');
		tabLink.classList.remove("active");
        tabLink.setAttribute('aria-selected', 'false');
	},
	showTabContent = (panel) => {
		panel.classList.remove("hide");		
		panel.setAttribute("tabindex", "0");
		if (panel.hasAttribute("inert")) {
			panel.removeAttribute("inert");
		}
		if (panel.hasAttribute("aria-hidden")) {
			panel.removeAttribute("aria-hidden");
		}
	},
	hideTabContent = (panel) => {
		panel.classList.add('hide');
		panel.setAttribute("inert", "");
		panel.setAttribute("tabindex", "-1");
		if (panel.hasAttribute("aria-hidden")) {
			panel.removeAttribute("aria-hidden");
		}
	},
	tabKeys = () =>{
		const firstTab = tabLinks[0];
		const lastTab = tabLinks[tabLinks.length-1];	
		for(let i = 0; i < tabLinks.length; i++){
			const nextTab = tabLinks[i + 1];
			const prevTab = tabLinks[i - 1];
			
			tabLinks[i].addEventListener("keydown", function (e) {
				// Listen for the up, down, left and right arrow keys, otherwise, end here
				if ([37, 38, 39, 40].indexOf(e.which) == -1) { return; }
	            		if (document.activeElement == lastTab) {
	            			switch (e.which) {
			                	case 37: // left arrow
			                	case 38: // up arrow
			                    		e.preventDefault();
			                    		e.stopPropagation();
			                    		if(prevTab){
			                    			prevTab.focus(); 
			                        		prevTab.click();
			                        	}
			                    		break;
			                	case 39: // right arrow
			               	case 40: // down arrow
			                    		e.preventDefault();
			                   	 	e.stopPropagation();
			                   	 	if(firstTab){
		                        			firstTab.focus();
		                        			firstTab.click();
		                        		}
		                    }
	            		}else if (document.activeElement == firstTab) {
	            			switch (e.which) {
			                	case 37: // left arrow
			                	case 38: // up arrow
			                    		e.preventDefault();
			                    		e.stopPropagation();
			                    		if(lastTab){
		                        			lastTab.focus();
		                        			lastTab.click(); //focus on last and click
		                        		}
			                    		break;
			                	case 39: // right arrow
			               	case 40: // down arrow
			                    		e.preventDefault();
			                   	 	e.stopPropagation();
			                   	 	if(nextTab){
		                        			nextTab.focus();
			                        		nextTab.click();
			                        	}
		            		}
	            		}else {
	            			switch (e.which) {
			                	case 37: // left arrow
			                	case 38: // up arrow
			                    		e.preventDefault();
			                    		e.stopPropagation();
			                    		if(prevTab){
			                        		prevTab.focus();
			                        		prevTab.click();
			                        	}
			                    		break;
			                	case 39: // right arrow
			               	case 40: // down arrow
			                    		e.preventDefault();
			                   	 	e.stopPropagation();
			                   	 	if(nextTab){
			                    			nextTab.focus();
			                        		nextTab.click();
			                        	}
		            		}
	            		}
			});
		}
	};
	
	return {
		init: init,
		initTab: initTab
	};
}